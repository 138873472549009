
.primaryContainer {
  min-height: calc(100vw - 150px);
}

* {
    margin: 0;
    padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, 'Russo One', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $gray-5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@mixin blurry-bg ($grayscale: 65%, $sepia: 0) {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  
  background-repeat: no-repeat, repeat;
  background-size: cover;
  filter: blur(4px) grayscale($grayscale) sepia($sepia);
  -webkit-filter: blur(4px) grayscale($grayscale) sepia($sepia);
}

@mixin offsetLR ($offset) {
  left: -$offset;
  right: $offset;
}