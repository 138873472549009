
.inventory {

    padding-top: 70px;

    .container {
        @include f-grid();

        h1 {
            @include f-item(12);
            
            font-size: 2.5em;
            margin-bottom: 60px;
        }

        .inventory-item {
            @include f-item(10, 1, 1);

            padding: 13px;
            display: flex;
            flex-direction: column;

            color: $black-2;
            line-height: 1.3;
            text-decoration: none;
            background-color: $white-02;
            box-shadow: 0px 0px 14px 0 #c8c8c8;

            @include screen-start-at(m) {
                @include f-item(4);
            }

            .field {
                font-weight: bold;
                margin-right: 7px;
            }

            .image-container {
                margin-bottom: 12px;

                overflow: hidden;

                display: flex;
                align-items: center;
                justify-content: center;

                background-repeat: no-repeat, repeat !important;
                background-position: center !important;
                background-size: cover !important;
                

                &:before {
                    content:'';
                    float:left;
                    padding-top:70%;
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}