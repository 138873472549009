
.home {
    @include f-grid();
    overflow: hidden;


    @include screen-start-at(m) {
        // margin-top: 180px;
    }

    .bus-img-container {
        @include f-item(10, 1, 1);
        position: relative;

        display: flex;
        align-items: center;

        @include screen-start-at(m) {
            @include f-item(7);
            align-self: center;
        }


        .bus-img {
            width: 100%;
        }
    }

    .contact {
        @include f-item(10, 1, 1);

        display: flex;
        flex-direction: column;

        h1 {
            text-align: center;
            margin-bottom: 70px;
        }

        .infos {
            font-size: 1.2em;
            display: flex;
            flex-direction: column;

            a {
                width: fit-content;
                margin-top: 30px;
                padding: 10px 20px;

                color: $white-02;
                font-size: 1.2em;
                text-decoration: none;

                

                background-color: $red-2;
                border-radius: 3px;

                &:hover {
                    background-color: $red-1;
                }
                
            }
        }
    }

    .container {
        @include f-grid();
        @include f-item(12);


        @include screen-start-at(m) {
            @include f-item(10, 1, 1);
        }

        &__contact {
            margin-bottom: 100px;
        }

        &__services {
            margin-bottom: 30px;

            @include screen-start-at(l) {
                margin-bottom: 200px;
            }
            @include screen-start-at(xl) {
                margin-bottom: 200px;
            }

            &:nth-child(even) {
                flex-direction: row-reverse;
            }
        }
    }

    .hero-container {
        width: 100%;
        height: 95vh;
        padding-top: 20px;

        background-image: url("../../img/san-fran.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;


        @include f-grid();
        
        margin-bottom: 250px;

        // display: flex;
        // flex-direction: column-reverse;
            
        @include screen-start-at(m) {
            padding-top: 125px;
        }
        @include screen-start-at(l) {
            padding-top: 150px;
        }
        @include screen-start-at(xl) {
            padding-top: 200px;
        }
    }

    .hero-content {
        @include f-item(12);

        padding: 15px;

        background-color: rgba(230, 230, 230, 0.5);

        display: flex;
        flex-direction: column;
        align-self: center !important;

        @include screen-start-at(m) {
            @include f-item(6, 3, 3);

            padding: 25px;

            a {
                width: inherit;
            }
        }

        a {
            padding: 10px;

            display: flex;
            align-items: center;
            justify-content: center;

            text-decoration: none;

            font-size: 1.3em;
            color: $gray-4;
            background-color: $blue-1;
        }

        h1 {
            font-size: 2.5em;
            margin-bottom: 30px;
        }

        span {
            font-size: 1.1em;
            margin-bottom: 15px;
            color: $black-2;
        }
    }

    .hero-img {
        @include f-item(12);
        margin-bottom: 40px;

        @include screen-start-at(m) {
            @include f-item(6);
            margin-bottom: 0;
            align-self: center;
        }
        
    }

    .service {
        @include f-item(10, 1, 1);

        z-index: 5;
        position: relative;
        margin-bottom: 30px;

        @include screen-start-at(m) {
            @include f-item(5);
            align-self: center;
            margin-bottom: 0;
        }

        
        // align-self: center;
        

        .service-content {

            padding: 25px;
        
            background-color: $red-4;
            border-radius: 3px;

            box-shadow: 0px 0px 8px 1px rgb(200, 200, 200);

            $mOffset: 70px;
            $lOffset: $mOffset;
            $xlOffset: $mOffset;

            @include screen-start-at(l) {
                position: absolute;
                @include offsetLR($lOffset);
            }
            @include screen-start-at(xl) {
                @include offsetLR($xlOffset);
            }
            
            &--flip {
                @include screen-start-at(l) {
                    position: absolute;
                    @include offsetLR(-$lOffset);
                }
                @include screen-start-at(xl) {
                    @include offsetLR(-$xlOffset);
                }
            }
            
            h3 {
                font-size: 1.7em;
                margin-bottom: 15px;
            }
        
            span {
                font-size: 1.1em;
            }
        }
    }
}