
.About {
    @include f-grid();
    
    margin-top: 50px;

    font-size: 1.35em;

    .container {
        @include f-item(12);
        @include f-grid();

        margin-bottom: 25px;

        @include screen-start-at(m) {
            @include f-item(6, 3, 3);
            margin-bottom: 50px;
        }

        h1 {
            @include f-item(12);
            width: 100%;
            margin-bottom: 15px;
            font-size: 2.5em;
            // text-align: center;
        }

        p, ul, h3 {
            @include f-item(12);
            margin-bottom: 12px;
        }

        li {
            margin-left: 20px;
            margin-bottom: 10px;
        }


    }

    .team-member {
        @include f-item(4, 1, 1);

        display: flex;
        flex-direction: column;

        img {
            max-width: 100%;
        }
    }
}