
.financing {
    @include f-grid();

    margin-top: 50px;

        @include screen-start-at(m) {
            margin-top: 100px;
        }

    .container {
        @include f-item(12);
        @include f-grid();

        margin-bottom: 50px;

        @include screen-start-at(m) {
            @include f-item(8, 2, 2);

        }

        .link-button {
            @include f-item(12);

            width: initial;
            padding: 12px;

            color: $black-2;
            font-weight: 600;
            font-size: 1.2em;
            text-decoration: none;
            
            background: $gray-3;
            border: 2px solid $gray-2;
            border-radius: 4px;
        }

        h1 {
            @include f-item(12);
            width: 100%;
            margin-bottom: 15px;
            font-size: 2.5em;
            // text-align: center;
        }

        p {
            @include f-item(12);
            font-size: 1.35em;
            margin-bottom: 12px;
        }



        .Collapsible {
            @include f-item(12);

            &__contentInner {
                background-color: $white-02;
                padding: 15px;
            }

            &__trigger {
                display: block;
                padding: 15px;
                
                font-size: 1.35em;
                font-weight: 400;
                text-decoration: none;
                color: $gray-4;
                position: relative;
                border: 1px solid white;
                padding: 10px;
                background: $gray-3;
                color: $black-2;


                &:after {
                    height: 10px;
                    width:  10px;
                    
                    border-right: 2.5px solid black;
                    border-bottom: 2.5px solid black;
                    transform: rotate(-45deg);

                    content: '';
                    position: absolute;
                    right: 15px;
                    top: 13px;
                    display: block;
                    transition: transform 300ms;
                 }

                &.is-open {
                    &:after {
                        transform: rotateZ(45deg);
                    }
                }

                &.is-disabled {
                    opacity: 0.5;
                    background-color: grey;
                }
            }

            &__custom-sibling {
                padding: 5px;
                font-size: 12px;
                background-color: #CBB700;
                color: black;
            }
        }

        .CustomTriggerCSS {
        background-color: lightcoral;
        transition: background-color 200ms ease;


        }

        .CustomTriggerCSS--open {
        background-color: darkslateblue;
        }
    }
}