.inventory-details {
    @include f-grid();

    margin-top: 40px;

    .container {
        @include f-item(12);

        position: relative;
        margin-bottom: 15px;

        &__half {
            @include screen-start-at('m') {
                @include f-item(6)
            }
        }

        &__no-bottom { margin-bottom: 0;}
    }

    .goBack {
        text-decoration: none;
        font-weight: bold;
        font-size: 1.3em;
    }

    .title {
        margin-bottom: 10px;
        color: $black-2 !important;
    }

    .title-image {
        max-width: 100%;
        max-height: 100%;
    }
}