
$black-1: #111;
$black-2: #222;

$blue-1: rgb(20, 20, 71);
$blue-2: rgb(40, 40, 160);

$gray-1: #444;
$gray-2: #66676F;
$gray-3: #d6d7df;
$gray-4: #eee;
$gray-5: #efefef;

$red-1: rgb(80, 15, 20);
$red-2: #934932;
$red-3: rgb(152, 30, 50);
$red-4: #dfd6d7;

$white-01: #fff;
$white-02: #fefefe;