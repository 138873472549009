.nav {
    width: 100vw;
    background-color: $white-01;
    box-shadow: 0px 0px 10px 2px rgb(200, 200, 200);
    border-top: 4px solid $blue-1;

    @include f-grid();

    a.logo {
        display: none;

        @include screen-start-at(l) {
            display: flex;
            align-self: center !important;

            @include f-item(5);
                img {
                    display: block;
                    max-height: 60px;
                }   
        }
    }

    ul {
        @include f-item(10, 1, 1);

        height: 110px;
        
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: center;

        @include screen-start-at(l) {
            @include f-item(7);
            justify-content: flex-end;
        }

        li {
            margin-left: 25px;

            a {
                font-weight: bold;
                text-decoration: none;
                color: $black-2;
                font-size: 1.3em;

                transition: color 0.3s;

                &:hover {
                    color: $blue-2;
                }
            }
        }
    }
}